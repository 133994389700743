.menu-container {
  position: absolute;
  right: 0;

  margin-top: 5px;
  margin-right: 8px;

  @media only screen and (max-width: 600px) {
    position: initial;

    margin-top: 0px;
    margin-right: 0px;

    &.share {
      position: absolute;

      margin-top: 5px;
      margin-right: 8px;
    }
  }

  .btn-menu {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;

    width: 45px;
    height: 45px;

    background-color: $orange;
    color: white;
    border-radius: 50%;

    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

    &:hover {
      color: white;
    }

    span {
      font-size: 16px;
    }

    @media only screen and (max-width: 600px) {
      display: none;

      &.share {
        display: flex;
      }
    }
  }

  .list-group {
    display: none;

    width: 250px;
    margin-top: 5px;

    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    &.open {
      display: block;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 0;
      width: initial;

      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);


      &.share {
        width: 230px;
        margin-top: 5px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
          0px 6px 10px 0px rgba(0, 0, 0, 0.14),
          0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      }
    }

    .list-group-item {
      color: initial;
      text-decoration: none;

      border: none;

      text-align: center;

      padding: 8px 10px;

      &.cursor-pointer {
        cursor: pointer;
      }

      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
}
