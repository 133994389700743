.filter-container {
  overflow: hidden;
  height: 0;

  border-top: 1px solid lightgray;

  &.open {
    height: auto;
    overflow: initial;

    padding: 10px 15px;
  }

  .form-group,
  .form-check {
    margin-bottom: 12px;
    label {
      font-weight: bold;
      font-size: 14px;

      // @media only screen and (max-width: 600px) {
      //   font-size: 16px;
      // }
    }
    .form-check-inline {
      margin-bottom: 3px;
      label {
        font-weight: lighter;
      }
    }

    select {
      padding-left: 5px;
      font-size: 14px;

      // @media only screen and (max-width: 600px) {
      //   font-size: 16px;
      // }
    }

    // @media only screen and (max-width: 600px) {
    //   margin-bottom: 16px;
    // }
  }

  .search {
    display: flex;
    margin: 0 auto;
    background-color: $orange;
    color: $white;
  }
}
