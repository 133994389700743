.account-container{
    .modal-header{
        h6{
            font-size: 18px;
            margin: auto 0;

            font-weight: bold;
            text-transform: uppercase;
        }

        button{
            padding: 0;
            margin: auto 0;

            svg{
                color: $orange;
            }
        }
    }

    #account-form{
        // padding: 20px;
        label{
            font-weight: bold;
        }
        
        .badge{
            background-color: $orange;

            margin-left: 5px;
            margin-bottom: 5px;
            padding: 5px 8px;
            
            // font-weight: lighter;
            color: $white;
        }

        .actions{
            display: flex;
            button{
                background-color: $orange;
                color: $white;

                &.btn-delete{
                    background-color: $red;
                }
            }
        }
    }
}