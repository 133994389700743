#login-form{
    width: 100%;

    .form-group{
        &:nth-child(2){
            margin-bottom: 0;
        }
    }
    
    label{
        font-weight: bold;
    }

    .actions{
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 5px;

        .btn-forgot-password{
            display: flex;
            padding: 0px;
            font-size: 14px!important;
        }
        .btn-login{
            display: flex;
            background-color: $orange;
            color: $white;
        }
    }

    .separator{
        display: flex;
        align-items: center;
        text-align: center;

        margin: 10px 0;

        &::before{
            content: '';
            flex: 1;
            border-bottom: 1px solid lightgray;
            margin-right: 10px;
        }
        &::after{
            content: '';
            flex: 1;
            border-bottom: 1px solid lightgray;
            margin-left: 10px;
        }
    }

    .social-login-container{
        display: flex;
        justify-content: center;
        a{
            display: flex;
            width: 30px;
            height: 30px;

            background-color: $orange;

            border-radius: 50%;
            margin: 0 5px;

            img{
                margin: auto;
                width: 14px;
            }
        }
    }

    .btn-sign-up{
        display: flex;
        margin: 30px auto 0;
        padding: 0;

        font-size: 16px!important;

        span{
            margin-left: 3px;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}