.restaurant-count {
  background-color: $orange;
  border-radius: 50%;
  color: white;

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: bold;

  border: none;

  // box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  animation: 1.5s ease-in-out 0s infinite pulse;

  cursor: pointer;
}

@-webkit-keyframes pulse {
  0% {
  -webkit-transform: scale(1, 1);
 }
  50% {
  -webkit-transform: scale(0.9, 0.9);
 }
  100% {
  -webkit-transform: scale(1, 1);
 };
 }
 
 @keyframes pulse {
  0% {
  transform: scale(1, 1);
 }
  50% {
  transform: scale(0.9, 0.9);
 }
  100% {
 transform: scale(1, 1);
 };
 }
 