.avatar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    background-color: $orange;
    border-radius: 50%;

    width: 40px;
    height: 40px;

    h6{
        color: white;
    }
}