html, body {
  font-family: $font-family;
}

h1, h2, h3, h4, h5, h6, p, small, input{
  font-family: $font-family;
  margin: 0;
}

label{
  margin: 0!important;
}


a{
  &:focus{
    outline: none!important;
    box-shadow: none!important;
  }
}
button{
  font-size: 12px!important;
  &:focus{
    outline: none!important;
    box-shadow: none!important;
  }
}

.orange{
  color: $orange;
}

.bold{
  font-weight: bold;
}
.bg_orange{
  background-color: $orange!important;
}

.error{
  border: 1px solid $red;
} 

//SPINNER
.spinner-border{
  margin: auto;
  border: 0.25em solid #ffa500;
  border-right-color: transparent;
}

//MODAL
.modal{
  padding-left: 0px!important;
  .modal-dialog{
    @media only screen and (max-width: 575px) {
      margin: 0;  
    }
    .modal-content{
      
      min-height: 200px;

      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);

      .modal-header{
        padding: 10px 15px;
      }
    }
  }
}

.info_icon{
  color: $orange;
  &.small{
    width: 14px;
    height: 14px;
  }
  &.large{
    width: 16px;
    height: 16px;
  }
}

@import 'fullscreen_loading';
@import 'default_location';

@import 'map';
@import 'login';
@import 'account';
@import 'register';
@import 'forgot_password';
@import 'reset_password';
@import 'sign_in';
@import 'add_restaurant';
@import 'view_restaurant';
@import 'contact';
@import 'restaurant_contact';

@import 'topbar';
@import 'menu';
@import 'autocomplete_search';
@import 'alert';
@import 'snackbar';
@import 'form_error_message';

@import 'avatar';
@import 'message';

@import 'custom_dropdown';

@import 'count';


//SEARCH
@import 'search/main.scss'