.search-items-container {
  position: relative;
  overflow-y: hidden;

  &.minimize {
    height: 0;
  }

  .search-count {
    position: absolute;
    z-index: 10;

    width: 100%;
    display: flex;
    justify-content: center;

    p {
      padding: 5px 10px;
      font-size: 13px;
      font-weight: bold;
      color: white;
      background-color: orange;

      border-radius: 0 0 5px 5px;

      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);

      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .search-items {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    height: auto;
    max-height: 300px;

    overflow-y: auto;

    li {
      cursor: pointer;
      padding: 10px;

      display: flex;

      border-left: none;
      border-right: none;

      min-height: 70px;

      @media only screen and (max-width: 600px) {
        min-height: 80px;
      }

      &:first-child {
        border-top: none;
        margin-top: 24px;

        @media only screen and (max-width: 600px) {
          margin-top: 22px;
        }
      }
      &:last-child {
        border-bottom: none;
      }

      &.selected {
        background-color: white;
        color: $orange;
      }

      &.empty {
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .name-address {
        width: 100%;
        margin: auto 8px auto 0;
        h6 {
          font-size: 15px;
          font-weight: bold;

          @media only screen and (max-width: 600px) {
            font-size: 16px;
          }

          span {
            font-size: 13px;
            font-weight: initial;
            font-style: italic;
          }

          svg {
            color: $orange;
            font-size: 16px;

            margin-left: 2px;
          }
        }
        p {
          font-size: 13px;
          line-height: 18px;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          // overflow: hidden;
        }
      }
      .directions-share-distance {
        margin: auto 10px auto 0;
        text-align: center;

        .buttons-container {
          display: flex;
          justify-content: center;
          .btn {
            padding: 0;

            &:first-child {
              margin-right: 10px;
            }

            svg {
              color: $orange;

              &.direction {
                @media only screen and (max-width: 600px) {
                  font-size: 26px;
                }
              }
              &.share {
                @media only screen and (max-width: 600px) {
                  font-size: 24px;
                }
              }
            }
          }
        }

        small{
          font-size: 12px;
        }
      }
    }
  }

  .loading {
    display: flex;
    padding: 20px;
  }
}