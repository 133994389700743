.map-container{
    .map{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        //INFO WINDOW
        .gm-ui-hover-effect{
            top: -7px!important;
            right: 0px!important;
            width: 30px!important;
            height: 30px!important;
            img{
                margin-top: 10px!important;
                margin-left: 7px!important;
                width: 18px!important;
                height: 18px!important;
            }
        }
        .gm-style-iw-d{
            overflow: auto!important;
        }
        .gm-style-iw-c{
            padding: 0;
            .info-window{
                max-width: 300px;
                min-width: 150px;

                @media only screen and (max-width: 300px) {
                    min-width: initial;
                }

                .name-actions{
                    padding: 24px 14px 0px;
                    text-align: center;

                    &.add{
                        padding: 24px 14px 10px;  
                    }
                    
                    .name{
                        font-weight: bold;

                        span{
                            font-size: 14px;
                            font-style: italic;
                            font-weight: lighter;
                        }

                        svg{
                            color: $orange;
                            font-size: 16px;
                            margin-left: 2px;
                        }
                    }
                    .rating{
                        margin-top: 4px;
                        font-size: 16px;
                    }
                    .actions{
                        display: flex;
                        justify-content: center;
                        .direction{
                            padding: 4px 6px;
                            svg{
                                color: $orange;
                                font-size: 26px;
                            }
                        }
                        .share{
                            background-color: transparent;
                            border: none;
                            padding: 4px 6px;
                            svg{
                                color: $orange;
                                font-size: 24px;
                            }
                        }
                    }
                }
                .view{
                    font-family: $font-family;
                    background-color: $orange;
                    color: $white;
                    width: 100%;
                    border-radius: 0;
                    font-size: 14px!important;
                }
            }
        }
        .gm-style .gm-style-iw-t::after{
            background: linear-gradient(45deg,rgba(255, 165, 0, 1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
            box-shadow: none;
        }
        //INFO WINDOW
    }

    .map-controls{
        background-color: white;
        border-radius: 5px;

        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
        z-index: 5;

        margin-right: 8px;

        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);

        button{     
            width: 100%;
            height: 100%;
            &:nth-child(even){
                border-top: 1px solid lightgray;
                border-bottom: 1px solid lightgray;
            }
            svg{
                font-size: 22px;
            }
        }
    }

    .btn-add{
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;

        display: flex;
        justify-content: center;
        align-items: center;


        width: 45px;
        height: 45px;

        margin-right: 8px;
        margin-bottom: 15px;

        background-color: $orange;
        color: white;
        border-radius: 50%;

        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
    }

    .btn-country-count{
        display: flex;
        justify-content: center;
        align-items: center;

        width: 30px;
        height: 30px;

        color: white;

        background-color: #ffa500;
        font-weight: bold;

        border-radius: 50%;

        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
    }
}