#forgot-password-form{
    width: 100%;

    h6{
        font-size: 16px;
        font-weight: bold;
    }

    .form-group{
        margin-top: 10px;
    }

    .actions{
        display: flex;
        justify-content: center;
        button{
            display: flex;
            margin: 0 5px;
            background-color: $orange;
            color: $white;
        }
    }
}