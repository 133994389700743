.default-location-container{
    .modal-header{
        .text{
            font-size: 18px;
            margin: auto 0;

            font-weight: bold;
            text-transform: uppercase;
        }

        button{
            padding: 0;
            margin: auto 0;

            svg{
                color: $orange;
            }
        }
    }

    .modal-body{
        display: flex;
        justify-content: center;
        align-items: center;

        form{
            width: 100%;
        
            label{
                font-weight: bold;
            }
            select{
                width: 100%;
            }

            small{
                font-weight: bold;
            }

            button{
                display: flex;
                justify-content: center;
                margin: auto;

                min-width: 80px;
    
                background-color: $orange;
                color: $white;
            }
        }

    }
}