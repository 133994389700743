.MuiSnackbar-root{
    z-index: 1040!important;
  
    // @media only screen and (max-width: 600px) {
    //   left: 0!important;
    //   right: 0!important;
    //   bottom: 0!important; 
    // }
  
    .MuiSnackbarContent-root{

      flex-wrap: nowrap;
  
      // @media only screen and (max-width: 600px) {
      //   border-radius: 0;
      // }
    
      .MuiSnackbarContent-message{
        font-family: $font-family;
        font-weight: bold;
        font-size: 16px;
      }
      .MuiSnackbarContent-action{
        button{
          padding: 0 8px;
        }
      }
  
      &.no-action{
        .MuiSnackbarContent-message{
          margin: auto;
        }
        .MuiSnackbarContent-action{
          display: none;
        }
      }
    }
  }