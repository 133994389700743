.fullscreen-loading{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  z-index: 100;
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}