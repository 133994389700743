.dropdown-multiselect {
  position: relative;
  width: 100%;

  .select-container {
    background-color: transparent;
    border: 1px solid #cccccc;
    border-radius: 4px;

    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    .input-container {
      width: 100%;
      padding-left: 10px;

      text-align: left;

      color: #495057;
      font-size: 14px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    button {
      padding: 0 2px 0 0;
    }
    svg {
      color: $orange;
      &.close {
        opacity: 1;
      }
      &.dropdown {
        font-size: 28px;
      }
    }
  }

  ul {
    display: none;

    position: absolute;

    width: 100%;
    max-height: 150px;

    overflow-y: auto;
    z-index: 100;

    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &.open {
      display: block;
    }

    li {
      padding: 18px 10px;
      font-size: 14px;
      height: 35px;

      display: flex;
      align-items: center;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.selected {
        svg {
          display: block;
          color: $orange;
        }
      }

      @media only screen and (max-width: 600px) {
        font-size: 16px;
      }

      svg {
        display: none;
        margin-left: auto;
        font-size: 22px;
      }
    }
  }
}
