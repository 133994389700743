.contact-container{
    .modal-header{
        .text{
            font-size: 18px;
            margin: auto 0;

            font-weight: bold;
            text-transform: uppercase;
        }

        button{
            padding: 0;
            margin: auto 0;

            svg{
                color: $orange;
            }
        }
    }

    #contact-form{
        // width: 100%;

        // padding: 20px;

        .header{
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            margin-bottom: 15px;
        }

        label{
            font-weight: bold;
        }

        input, select{
            font-size: 16px;
        }

        textarea{
            font-size: 16px;
            min-height: 100px;
        }
        button{
            display: flex;
            margin: auto;
            background-color: $orange;
            color: white;
        }
    }
}