.reset-password-container{

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .card{
        max-width: 500px;
        width: 500px;

        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    
        .card-header{
            background-color: transparent;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
        }
    
        #reset-password-form{
            label{
                font-weight: bold;
            }
    
            button{
                display: flex;
                margin: auto;
    
                background-color: $orange;
                color: $white;
            }
        }
    }
}