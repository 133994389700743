.autocomplete-search-container{
    position: relative;

    width: 100%;
    max-width: 350px;
    height: 36px;

    margin-top: 5px;
    margin-left: 8px;

    @media only screen and (max-width: 600px) {
        // padding: 0 0 0 45px;

        margin-top: 0px;
        margin-left: 0px;

        border-radius: 0!important;

        height: 50px;

        max-width: initial;
    }

    .icon-search{
        position: absolute;
        z-index: 10;

        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        @media only screen and (max-width: 600px) {
            height: initial;
        }

        svg{
            color: $orange;
            @media only screen and (max-width: 600px) {
                font-size: 26px;
            }
        }
    }

    .icon-clear{
        position: absolute;
        z-index: 10;

        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding-right: 5px;

        @media only screen and (max-width: 600px) {
            height: initial;
            padding-right: 0px;
        }

        button{
            width: 100%;
            height: 100%;

            background-color: white;
            border: none;
            padding: 5px;

            svg{
                color: $orange;
                @media only screen and (max-width: 600px) {
                    font-size: 26px;
                }
            }
        }
    }
    
    
    .autocomplete-input{
        width: 100%;
        height: 100%;

        padding: 20px 40px;

        background-color: white;
        border-radius: 5px!important;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
        border: none;

        &:focus{
            outline: none;
            border: none;
        }

        @media only screen and (max-width: 600px) {
            border-radius: 0px!important;
        }
    }

}