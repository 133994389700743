.add_restaurant{
    .modal-header{
        .text{
            font-size: 18px;
            margin: auto 0;

            font-weight: bold;
            text-transform: uppercase;
        }

        button{
            padding: 0;
            margin: auto 0;

            svg{
                color: $orange;
            }
        }
    }

        #add-restaurant-form{
            // padding: 20px;

            label{
                font-weight: bold;
            }

            input, .phone-numbers-dropdown{
                font-size: 16px;
            }

            .meat-types-container{
                margin-bottom: 0;

                .meat-type-options-container{
                    margin-top: 3px;
                    label{
                        // font-size: 14px;
                        font-weight: normal;
                    }
                }

                .meat-types-items-container{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
        
                    .item-container{
                        padding: 10px;
                        .item{
                            text-align: center;
                            h6{
                                font-size: 16px;
                                font-weight: bold;
                            }
                            label{
                                font-size: 14px;
                                font-weight: normal;
                            }

                            .form-group{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            button{
                display: flex;
                margin: 10px auto 0 auto;
                background-color: $orange;
                color: $white;
            }
        }
}