.restaurant-contact-container{
    min-height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    #restaurant-contact-form{
        // width: 100%;

        // padding: 20px;

        .header{
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            margin-bottom: 15px;
        }

        label{
            // font-size: 16px;
            font-weight: bold;
        }

        input, select{
            font-size: 16px;
        }

        textarea{
            font-size: 16px;
            min-height: 100px;
        }
        button{
            display: flex;
            margin: auto;
            background-color: $orange;
            color: white;
        }
    }
}