.view-restaurant{

    min-height: initial;

    &.loading{
        min-height: 200px;
    }

    .modal-header{
        .title-container{
            margin-right: 10px;

            h6{
                font-size: 18px;
    
                font-weight: bold;

                margin: 0;

                span{
                    text-transform: initial;
                    font-size: 16px;
                    font-weight: lighter;
                    font-style: italic;
                }

                svg{
                    color: $orange;
                    font-size: 16px;
                    margin-left: 2px;
                }
            }
            p{
                font-size: 14px;

                margin: 0;

                // overflow: hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
            }
        }
        .buttons-container{
            display: flex;
            margin: auto 0;

            button{
                padding: 0;

                svg{
                    color: $orange;
                }
                
                &.report{
                    margin-right: 10px;
                }
            }
        }
    }

    .modal-body{
        
        label{
            font-weight: bold;
        }

        .row{
            @media only screen and (max-width: 992px) {
                display: block;   
            }
        }

        .phone-numbers-container{
            span, a{
                font-size: 16px;
            }
        }

        .meat-types-container{
            .slick-slider{
                margin-top: 10px;
                .slick-slide{
                    display: flex;
                    justify-content: space-around;

                    .item-container{
                        .item{
                            text-align: center;

                            .title{
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                font-weight: bold;

                                margin: 0;

                                img{
                                    position: absolute;
                                    width: 16px;
                                    height: 16px;
                                    right: -22px;
                                }
                            }

                            .meat-container{
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .meat-item{
                                    h6{
                                        margin: 5px 0;
                                        font-size: 16px;
                                    }
                                    button{
                                        padding: 0px;
                                        
                                        svg{
                                            margin: auto;
                                            font-size: 24px;
    
                                            &.active{
                                                color: $orange;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-arrow{
                    z-index: 10;
                    &:before{
                        color: $orange;
                    }
                    &.slick-prev{
                        left: 0;
                    }
                    &.slick-next{
                        right: 0;
                    }
                }
            }
        }


        .btn-sign-in{
            margin: 20px auto 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            padding: 0;
            font-size: 16px!important;

            span{
                font-weight: bold;
                text-decoration: underline;

                margin-right: 3px;
            }
        } 

        .validate-container{
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;
            
            margin-top: 30px;

            .message-container{
                padding: 0;
                color: black;

                svg{
                    color: $orange;
                    font-size: 20px;
                }

                h6{
                    margin-top: 4px;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
            .validate-form{
                h6{
                    font-weight: bold;
                }
                div{
                    display: flex;
                    button{
                        width: 100%;
                        background-color: $orange;
                        color: white;
                        margin: 5px 5px 0 5px;
                    }
                }
            }
        }
    }


}