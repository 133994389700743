.message-container-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .message-container{
        margin: auto;
        .message{
            margin: auto;
            font-size: 16px;
            font-weight: bold;
            max-width: 500px;
            // width: 100%;
        }
        button{
            margin-top: 10px;
            color: white;
            background-color: $orange;
        }
    }
}