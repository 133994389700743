.sign-in-container{
    .modal-header{
        .text{
            font-size: 18px;
            margin: auto 0;

            font-weight: bold;
            text-transform: uppercase;
        }

        button{
            padding: 0;
            margin: auto 0;

            svg{
                color: $orange;
            }
        }
    }

    .modal-body{
        // padding: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

    }
}