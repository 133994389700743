.input-form {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 5px;

  height: 40px;

  button {
    background-color: white;
    border: none;
    padding: 5px;

    color: $orange;

    &.filter.open {
      transform: rotate(180deg);
    }

    &.menu {
      display: none;
      padding: 5px 10px;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
  }

  input {
    height: 100%;
    padding: 8px 5px;

    border-radius: 0;
    border: none;

    text-overflow: ellipsis;

    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}
