.search-container {
  position: absolute;

  margin-top: 5px;
  margin-left: 8px;

  width: 100%;
  max-width: 350px;

  background-color: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  @media only screen and (max-width: 600px) {
    position: initial;
    max-width: 100%;

    margin-top: 0px;
    margin-left: 0px;

    border-radius: 0;
  }

  .search-minimize {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    background-color: white;

    position: absolute;

    left: 0;
    right: 0;

    width: 70px;
    height: 25px;

    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    border-top: 1px solid lightgray;

    border-radius: 0 0 5px 5px;

    &:focus {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
    }

    svg {
      color: $orange;
      font-size: 30px;

      transform: rotate(180deg);

      &.minimize {
        transform: rotate(0);
      }
    }
  }

  @import "search_input";
  @import "search_filters";
  @import "search_items";
}
