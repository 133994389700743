#register-form{
    width: 100%;
    label{
        font-weight: bold;
    }
    .actions{
        display: flex;
        justify-content: center;
        button{
            margin: 0 5px;
            background-color: $orange;
            color: $white;
        }
    }
}